import {
  Box, Button, Grid, Menu, MenuItem, ThemeProvider, Typography, createTheme,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import RefreshIcon from '@material-ui/icons/Refresh';
import { HomeRounded } from "@material-ui/icons";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
// import RefreshIcon from '@mui/icons-material/Refresh';
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PeopleIcon from "@material-ui/icons/People";
import PhoneIcon from "@material-ui/icons/Phone";
import { clientActions } from "getDetails";
import React, { createRef } from "react";
import { connect } from "react-redux";
import { NavLink, matchPath, withRouter } from "react-router-dom";
import { compose } from "redux";
import { authActions } from "../../../auth";
import styles from "./header.module.scss";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from '@material-ui/icons/Notifications';

const useStyles = (theme) => ({
  activeNavLink: {
    "&, &:hover": {
      color: theme.palette.primary.main, borderBottom: `2px solid  ${theme.palette.primary.main}`,
    },
  }, buyBtn: {
    color: "#fff",
    padding: "6px 6px 6px 0",
    width: "10rem",
    fontSize: "14px",
    boxShadow: " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    fontWeight: "800",
    borderRadius: "8px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    backgroundColor: "#221c53",
    "&:hover": {
      background: "#fff", color: theme.palette.primary.main,
    },
    "& .MuiTouchRipple-root": {
      opacity: 0.2, zIndex: -1,
    },
  }, profileButton: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    backgroundColor: "rgb(243, 246, 249)",
    color: "rgb(62, 80, 96)",
    border: "1px solid rgb(224, 227, 231)",
    borderRadius: "10px",
    cursor: "pointer",
    transitionProperty: "all",
    transitionDuration: "150ms",
    textTransform: "capitalize",
    margin: "0 15px 0px 0px",
    "&:hover": {
      background: "rgb(231, 235, 240)", borderColor: "rgb(205, 210, 215)",
    },
  }, boxCenter: {
    display: "flex", alignItems: "center",
  },
});

const ButtonTheme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: "25px !important",
      },
    }, MuiMenu: {
      paper: {
        top: "50px !important", // left: "965px !important"
      },
    }, MuiTypography: {
      root: {
        fontSize: "14px",
      },
    },
  },
});

const StyledButton = withStyles((theme) => ({
  root: {
    height: "3.5rem",
    marginRight: "2%",
    marginTop: "0.5rem",
    padding: "0 2%",
    borderRadius: 0,
    fontWeight: 800,
    color: theme.palette.grey[700],
    borderBottom: `2px solid #fff`,
    "&:hover": {
      background: "none", borderBottom: `2px solid ${theme.palette.grey[100]}`, color: theme.palette.grey[900],
    },
    "& .MuiTouchRipple-root": {
      opacity: 0.2, zIndex: -1,
    },
  },
}))(Button);

const BuyCreditsButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    padding: "6px 6px 6px 0",
    width: "10rem",
    fontSize: "14px",
    boxShadow: " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    fontWeight: "800",
    borderRadius: "8px",
    letterSpacing: "1px",
    textTransform: "capitalize",
    backgroundColor: "#221c53",
    "&:hover": {
      background: "#fff", color: theme.palette.primary.main,
    },
    "& .MuiTouchRipple-root": {
      opacity: 0.2, zIndex: -1,
    },
  },
}))(Button);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false, anchorEl: null,
      menuAnchor: null,
    };
    this.expandMoreIconRef = createRef();
    this.expandRef = createRef();
  }

  handleOpen = (event) => {
    this.setState({ open: true });
  };
  handleClose = (event) => {
    this.setState({ open: false });
  };

  checkActive = (_, location) => {
    if (!location) return false;
    const homeURLs = ["/home", "/documents", "/templates", "/stamps", "/folders",];

    const match = matchPath(location.pathname, {
      path: homeURLs, exact: false, strict: true,
    });

    return !!match;
  };

  handleLogOut = () => {
    this.props.signOut();
  };

  render() {
    const { classes, userName } = this.props;
    const { open } = this.state;

    const setAnchorEl = (val) => {
      this.setState({ anchorEl: val });
    };
    const setMenuAnchor = (val) => {
      this.setState({ menuAnchor: val });
    };

    const onProfileButtonClick = (event) => {
      setAnchorEl(this.expandMoreIconRef.current);
    };
    const onNotificationIconClick = (event) => {
      setMenuAnchor(this.expandRef.current);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleNotificationClose = () => {
      setMenuAnchor(null)
    };

    // console.log("Profile", this.props.profileData);
    // const name = this.props.profileData?.data?.name?.split(" ");
    // console.log("Name", name);
    // let userName;
    // console.log("Lengt", name?.length);
    // if (name?.length < 1) {
    //   alert("Hello");
    //   let a = name?.[0];
    //   console.log(a);
    //   userName = a.slice(0, 1);
    //   console.log("Username", userName);
    // }
    const eventDescriptions = {
      linkedin_request: {
        title: "LinkedIn Request",
        description: "LinkedIn request notification is enabled."
      },
      din_batch_request: {
        title: "DIN Batch Request",
        description: "DIN batch request notification is enabled."
      },
      linkedin_bulk_request: {
        title: "LinkedIn Bulk Request",
        description: "LinkedIn Bulk requests notification is enabled."
      }
    };

    return (
      <Box className={styles.navbar}>
        <Grid container justifyContent="space-between">
          <Grid item xs={3}>
            <StyledButton
              component={NavLink}
              strict
              to="/home"
              size="large"
              activeClassName={classes.activeNavLink}
              isActive={this.checkActive}
              startIcon={<HomeRounded />}
            >
              Home
            </StyledButton>
          </Grid>
          <div style={{ display: 'flex', flexDirection: 'row' }}
          >
            <Grid item className={classes.boxCenter}>
              <ThemeProvider theme={ButtonTheme}>
                {/* <Button
                className={classes.profileButton}
                onClick={onProfileButtonClick}
                startIcon={
                  !this.props?.userTeamDetails ? (
                    <AccountCircleIcon fontSize={"25"} />
                  ) : (
                    <PeopleIcon fontSize={"25"} />
                  )
                }
                endIcon={<ExpandMoreIcon innerRef={this.expandMoreIconRef} />}
                variant="outlined"
              >
                {!this.props?.userTeamDetails
                  ? this.props?.userDetails?.name
                  : this.props?.userTeamDetails?.team_name}
              </Button> */}
                {/* <Button>
                <AccountBalanceWalletIcon/> Credits
              </Button> */}
                {this.props.userDetails?.email_notifications_enabled && <>
                  <div role="button" style={{ position: "relative", display: "inline-block", cursor: "pointer", padding: 8, borderRadius: "50%" }}
                       onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0.1)")}
                       onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
                       onClick={onNotificationIconClick}>
                    <Grid className={classes.boxCenter}>
                      <NotificationsIcon style={{ fill: '#212121' }} innerRef={this.expandRef} />
                    </Grid>
                  </div>
                  <Menu
                    anchorEl={this.state.menuAnchor}
                    keepMounted
                    open={Boolean(this.state.menuAnchor)}
                    onClose={handleNotificationClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    PaperProps={{
                      style: { borderRadius: "12px" }, // Custom shadow
                      elevation: 3,
                    }}
                  >
                  <MenuItem style={{ backgroundColor: 'transparent' }} disableRipple onClick={handleNotificationClose}>
                    <Grid className={classes.boxCenter} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                      {this.props.userDetails?.email_trigger_events.map((eventKey, index, array) => {
                        const eventData = eventDescriptions[eventKey] || {
                          title: "Unknown Event",
                          description: "No description available."
                        };
                        return (<>
                          <div key={index} style={{width:'100%'}}>
                            <Typography style={{ fontWeight: "600" }}>
                              {eventData.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {eventData.description}
                            </Typography>
                            {index !== array.length - 1 && (
                              <hr style={{ width: "100%", border: "none", borderBottom: "1.5px solid rgba(0, 0, 0, 0.05)", margin: "8px 0" }} />
                            )}
                          </div>
                        </>)
                      })}
                    </Grid>
                  </MenuItem>
                </Menu>
                </>}

                <MenuItem>
                  <Grid className={classes.boxCenter}>
                    <AccountBalanceWalletIcon
                      style={{ margin: "0px 0px 0px 3px", width: "30px", color: "green", fontSize: "30px" }}
                    />
                    {console.log("propsprops", this.props.userDetails?.
                      total_credits)}

                    <div>
                      <Typography style={{
                        fontWeight: "10", marginLeft: "10px", fontSize: "10px", color: "black"
                      }}>
                        Credits
                      </Typography>
                      <Typography style={{
                        fontWeight: "500", marginLeft: "10px", fontSize: "10px", color: "black"
                      }}>
                        {this.props.userDetails?.total_credits}
                      </Typography>
                    </div>

                  </Grid>
                  {/* <RefreshIcon
                    onClick={() => this.props.getDetails()}
                    style={{ fontWeight: 400, marginLeft: "15px", fontSize: "16px",  marginRight:"12px"}}
                  /> */}
                </MenuItem>

              </ThemeProvider>
            </Grid>


            <Grid item className={classes.boxCenter}>
              <ThemeProvider theme={ButtonTheme}>
                <Button
                  className={classes.profileButton}
                  onClick={onProfileButtonClick}
                  startIcon={!this.props?.userTeamDetails ? (<AccountCircleIcon fontSize={"25"} />) : (
                    <PeopleIcon fontSize={"25"} />)}
                  endIcon={<ExpandMoreIcon innerRef={this.expandMoreIconRef} />}
                  variant="outlined"
                >
                  {!this.props?.userTeamDetails ? this.props?.userDetails?.name : this.props?.userTeamDetails?.team_name}
                </Button>
                <Menu
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={this.state.anchorEl}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <Grid className={classes.boxCenter}>
                      <PhoneIcon
                        style={{ margin: "0px 0px 0px 3px", width: "22px" }}
                      />
                      <Typography>&nbsp;Mobile: &nbsp;</Typography>
                      <Typography style={{ fontWeight: "600" }}>
                        {this.props.userDetails?.user_mobile || "-"}
                      </Typography>
                    </Grid>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Grid className={classes.boxCenter}>
                      <EmailIcon
                        style={{ margin: "0px 0px 0px 3px", width: "22px" }}
                      />
                      <Typography>&nbsp;Email: &nbsp;</Typography>
                      <Typography style={{ fontWeight: "600" }}>
                        {this.props.userDetails?.email || "-"}
                      </Typography>
                    </Grid>
                  </MenuItem>
                  {this.props?.userTeamDetails ? (<>
                    <MenuItem onClick={handleClose}>
                      <Grid className={classes.boxCenter}>
                        <AccountCircleIcon
                          style={{ margin: "0px 0px 0px 3px", width: "22px" }}
                        />
                        <Typography>&nbsp;Name: &nbsp;</Typography>
                        <Typography style={{ fontWeight: "600" }}>
                          {this.props.userDetails.name}
                        </Typography>
                      </Grid>
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose}>
                        <Grid className={classes.boxCenter}>
                          <PhoneIcon style={{ margin: '0px 0px 0px 3px', width: "22px" }} />
                          <Typography>
                            &nbsp;Owner Mobile: &nbsp;
                          </Typography>
                          <Typography style={{ fontWeight: "600" }}>
                            {this.props.userTeamDetails?.member_details?.member_added_by}
                          </Typography>
                        </Grid>
                      </MenuItem> */}
                    <MenuItem onClick={handleClose}>
                      <Grid className={classes.boxCenter}>
                        <AccountBalanceIcon
                          style={{ margin: "0px 0px 0px 3px", width: "22px" }}
                        />
                        <Typography>&nbsp;Credits Allotted: &nbsp;</Typography>
                        <Typography style={{ fontWeight: "600" }}>
                          {this.props.userTeamDetails?.data?.member_details?.total_credits_allotted}
                        </Typography>
                      </Grid>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <Grid className={classes.boxCenter}>
                        <AccountBalanceWalletIcon
                          style={{ margin: "0px 0px 0px 3px", width: "22px" }}
                        />
                        <Typography>&nbsp;Credits Left: &nbsp;</Typography>
                        <Typography style={{ fontWeight: "600" }}>
                          {this.props.userTeamDetails?.data?.member_details?.credits_left}
                        </Typography>
                      </Grid>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <Grid className={classes.boxCenter}>
                        <AccountBalanceWalletIcon
                          style={{ margin: "0px 0px 0px 3px", width: "22px" }}
                        />
                        <Typography>
                          &nbsp;Email Credits Left: &nbsp;
                        </Typography>
                        <Typography style={{ fontWeight: "600" }}>
                          {this.props.userTeamDetails?.data?.member_details?.email_credits_left}
                        </Typography>
                      </Grid>
                    </MenuItem>
                  </>) : (<>
                    <MenuItem onClick={handleClose}>
                      <Grid className={classes.boxCenter}>
                        <AccountBalanceWalletIcon
                          style={{ margin: "0px 0px 0px 3px", width: "22px" }}
                        />
                        <Typography>&nbsp;Credits Left: &nbsp;</Typography>
                        <Typography style={{ fontWeight: "600" }}>
                          {this.props.userDetails?.total_credits}
                        </Typography>
                      </Grid>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <Grid className={classes.boxCenter}>
                        <AccountBalanceWalletIcon
                          style={{ margin: "0px 0px 0px 3px", width: "22px" }}
                        />
                        <Typography>
                          &nbsp;Email Credits Left: &nbsp;
                        </Typography>
                        <Typography style={{ fontWeight: "600" }}>
                          {this.props.userDetails?.email_credits}
                        </Typography>
                      </Grid>
                    </MenuItem>
                  </>)}
                  {this.props.userDetails?.subscription && <>
                    <MenuItem onClick={handleClose}>
                      <Grid className={classes.boxCenter}>
                        <AccountBalanceWalletIcon
                          style={{ margin: "0px 0px 0px 3px", width: "22px" }}
                        />
                        <Typography>&nbsp;Subscription Email Credits: &nbsp;</Typography>
                        <Typography style={{ fontWeight: "600" }}>
                          {this.props.userDetails?.subscription_credits.total_email_credits ?? 0}
                        </Typography>
                      </Grid>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <Grid className={classes.boxCenter}>
                        <AccountBalanceWalletIcon
                          style={{ margin: "0px 0px 0px 3px", width: "22px" }}
                        />
                        <Typography>
                          &nbsp;Subscription Phone Credits: &nbsp;
                        </Typography>
                        <Typography style={{ fontWeight: "600" }}>
                          {this.props.userDetails?.subscription_credits.total_phone_credits ?? 0}
                        </Typography>
                      </Grid>
                    </MenuItem>
                  </>}
                </Menu>
              </ThemeProvider>
            </Grid>

          </div>
        </Grid>
      </Box>);
  }
}

const mapStateToProps = (state) => {
  return {
    profileData: state.clientData.profileData,
    userDetails: state.clientData.userDetails,
    userTeamDetails: state.clientData.userTeamDetails,
  };
};

const mapDispatchToProps = {
  signOut: authActions.logout, getProfileData: clientActions.getProfileDetails, getDetails: authActions.getDetails
};

export default compose(
  withStyles(useStyles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Header);
