import {
    Box,
    Button,
    CircularProgress,
    Grid,
    LinearProgress,
    Tooltip,
    Typography,
    withStyles,
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import React, { useEffect, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import PublishIcon from '@material-ui/icons/Publish';
import TextFloatingFilterComponent from "views/components/custom-floating-filter/TextFloatingFilterComponent";
import { downloadBulkRequestAPI, getBatchRequestAPI, uploadBulkRequestAPI } from "getDetails/manager";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { DropzoneArea } from '@pandemicode/material-ui-dropzone'
import { GetApp } from "@material-ui/icons";
import StatusTag from "views/components/StatusTags";
import UploadIcon from "../../../../assets/UploadIcon.svg"
import CloudUpload from "../../../../assets/CloudUpload.svg"
import CustomSnackbar from "views/components/custom-snackbar";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);

const styles = (theme) => ({
    heading: {
        width: "100%",
        fontSize: "30px",
    },
    tableHeading: {
        width: "100%",
        // border: "solid 1px",
        borderColor: "#babfc7",
        borderBottom: "none",
        backgroundColor: "#fff",
        height: "60px",
        padding: "0 18px 0px 18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    teamsTable: {
        margin: "25px 25px 0px",
    },
    table: {
        "& .ag-icon ": { fontFamily: "agGridAlpine !important" },
        "& .ag-theme-alpine .ag-radio-button-input-wrapper": {
            fontFamily: "agGridAlpine !important",
        },
        "& .ag-header-row": {
            background: "#fff !important",
        },
        "& .ag-header-cell-label , .ag-floating-filter-full-body": {
            display: "flex",
            justifyContent: "flex-start",
        },
        "& .ag-header-cell-label > .ag-header-cell-text": {
            fontSize: "14.5px",
            fontWeight: "800",
            color: "black",
        },
        "& .ag-row .ag-cell ": {
            display: "flex",
            justifyContent: "flex-start" /* align horizontal */,
            alignItems: "center",
        },
        "& .ag-paging-row-summary-panel , .ag-paging-page-summary-panel": {
            color: "black !important",
            fontWeight: "700 !important",
        },
    },
    totalResults: {
        textTransform: "capitalize",
        fontWeight: "600",
        color: "black",
        fontSize: "19px",
    },
    button: {
        color: "#fff",
        padding: "8px 10px",
        // width: "10rem",
        fontSize: "14px",
        boxShadow:
            " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
        fontWeight: "800",
        borderRadius: "8px",
        letterSpacing: "1px",
        textTransform: "capitalize",
        backgroundColor: "#221c53",
        "&:hover": {
            background: "#fff",
            color: theme.palette.primary.main,
        },
        "& .MuiTouchRipple-root": {
            opacity: 0.2,
            zIndex: -1,
        },
    },
    downloadButton: {
        height: "2rem",
        width: "8.8rem",
        color: "#fff",
        padding: "8px 10px",
        fontSize: "14px",
        boxShadow:
            " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
        fontWeight: "400",
        letterSpacing: "1px",
        backgroundColor: "#221c53",
        "&:hover": {
            background: "#fff",
            color: theme.palette.primary.main,
        },
        "& .MuiTouchRipple-root": {
            opacity: 0.2,
            zIndex: -1,
        },
    },
    buttonDisabled: {
        backgroundColor: "#ccc !important",
        color: "#888 !important",
        boxShadow: "none !important",
        cursor: "not-allowed !important",
        padding: "8px 10px",
        fontSize: "14px",
        fontWeight: "800",
        borderRadius: "8px",
        letterSpacing: "1px",
        textTransform: "capitalize",
        "&:hover": {
            background: "#ccc !important",
            color: "#888 !important",
        },
        "& .MuiTouchRipple-root": {
            opacity: 0.2,
            zIndex: -1,
        },
    },
    statusChip: {
        backgroundColor: "rgb(44, 206, 158)",
        color: "white",
        textTransform: "capitalize",
        borderRadius: "5px",
    },
    ellipsis: {
        fontSize: "13px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    dialogHeading: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        paddingBottom: "20px",
    },
    dialogHeadingIcon: {
        border: "1px solid #0000001A",
        padding: "0px",
        width: "40px",
        height: "40px",
        borderRadius: "8px",
        borderWidth: "1px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    dialogSubHeading: {
        display: "grid",
        justifyContent: "center"
    },
    dialogSubHeadingh1: {
        margin: 0,
        fontFamily: "Poppins, sans-serif",
        fontWeight: 500,
        fontSize: "16px",
        letterSpacing: "0%",
    },
    dialogSubHeadingp: {
        margin: 0,
        fontFamily: "Poppins, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
        letterSpacing: "0%",
        color: "#00000099"
    },
    radioGroup: {
        display: "flex",
        justifyContent: "space-between"
    },
    radioGroupRequestBox: {
        width: "47%",
        background: "#fff",
        borderRadius: "0.5rem"
    },
    formControlLabel: {
        paddingLeft: "5%",
        paddingRight: "41%",
        margin: 0,
        height: "3rem",
        whiteSpace: "nowrap",
        overflow: "hidden", // Hides overflow text
        textOverflow: "ellipsis"
    },
    previewChip: {
        // minWidth: 160,
        maxWidth: 250,
        marginTop: "8px",
        backgroundColor: "#042A940D"
    },
    customDropzone: {
        border: "2px dashed #042A9480 !important",  // Force border style
        borderRadius: "18px !important",  // Rounded corners
        backgroundColor: "#f9f9f9 !important",  // Light background
    },
    dialogPaper: {
        borderRadius: "18px",
    }
});

const BulkRequestDinGst = ({ classes }) => {
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([] | '');
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [uploadLoader, setUploadLoader] = useState(false);
    const [value, setValue] = useState('DIN');
    const [files, setFiles] = useState([])
    const [errormsg, setErrormsg] = useState('')
    const [dropzoneKey, setDropzoneKey] = useState(Date.now());
    const requestDIN = useRef(null);
    const requestGST = useRef(null);
    const [downloadingRows, setDownloadingRows] = useState([]);

    const handleFileChange = (files) => {
        setFiles(files)
    }

    const handleChange = (event) => {
        setValue(event.target.value);
        setFiles([])
        setDropzoneKey(Date.now());
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const uploadBulkFile = async (e) => {
        e.preventDefault();
        const file = files[0]
        let formData = new FormData();
        formData.append("file", file);
        formData.append("batch_type", value);
        setUploadLoader(true)
        try {
            await uploadBulkRequestAPI(formData);
            handleClose()
            getTable();
        } catch (error) {
            console.log("There is an error while fetching your request", error.message)
            setErrormsg(error.message)
        } finally {
            handleClose()
            getTable();
            setUploadLoader(false)
        }
    }

    const DateRenderer = (params) => {
        return (
            <Typography component={"span"} style={{ fontSize: 13 }}>
                {dayjs.utc(params.value).local().format("D/MM/YYYY, ddd, h:mm A")}
            </Typography>
        );
    };

    const StatusRenderer = ({ params }) => {
        const isDownloading = downloadingRows.includes(params.data.client_id);
        return (
            <Grid
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {params.value !== "COMPLETED" ? (
                    <StatusTag status={params.value?.toLowerCase()} />
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.downloadButton}
                        startIcon={!isDownloading && <GetApp />}
                        onClick={async () => {
                            setDownloadingRows((prev) => [...prev, params.data.client_id]);
                            try {
                                const response = await downloadBulkRequestAPI(
                                    params.data.client_id,
                                    params.data.batch_type
                                );
                                const downloadUrl = response.data.data.output_s3_key;
                                if (downloadUrl) {
                                    const download = document.createElement("a");
                                    download.href = downloadUrl;
                                    document.body.appendChild(download);
                                    download.click();
                                    document.body.removeChild(download);
                                } else {
                                    console.error("Download URL not found:", response);
                                }
                            } catch (error) {
                                console.error("Error downloading file:", error);
                            } finally {
                                setDownloadingRows((prev) =>
                                    prev.filter((id) => id !== params.data.client_id)
                                );
                            }
                        }}
                        disabled={isDownloading}
                    >
                        {isDownloading ? <CircularProgress size={24} /> : "Download"}
                    </Button>
                )}
            </Grid>
        );
    };

    // Column Definitions: Defines the columns to be displayed.
    const colDefs = [
        { headerName: "Client ID", field: "client_id" },
        { headerName: "Batch Type", field: "batch_type" },
        { headerName: "Created At", field: "created_at", cellRenderer: DateRenderer },
        { headerName: "Credits Used", field: "credits_used" },
        {
            headerName: "Status", field: "status",
            floatingFilter: false,
            cellRenderer: (params) => <StatusRenderer params={params} />,
        },
    ];

    const ToolTipRenderer = (params) => {
        return (
            <Tooltip title={params.value} classes={classes.tooltip}>
                <Typography className={classes.ellipsis}>
                    {params.value}
                </Typography>
            </Tooltip>
        );
    };
    const defaultColDef = {
        sortable: true,
        flex: 1,
        minWidth: 200,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        floatingFilterComponent: TextFloatingFilterComponent,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        suppressMenu: true,
        cellClass: "multiline",
        cellRenderer: ToolTipRenderer,
    };

    const getTable = async () => {
        setLoader(true)
        try {
            const response = await getBatchRequestAPI();
            setRowData(response.data.data.requests)
        } catch (error) {
            console.error("Error fetching batch request data:", error);
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getTable()
    }, [])

    if (value == "DIN" && requestDIN.current) {
        requestDIN.current.style.background = "#042A940D";
        requestGST.current.style.background = "#fff";
    } else if (value == "GST" && requestGST.current) {
        requestGST.current.style.background = "#042A940D";
        requestDIN.current.style.background = "#fff";
    }

    const clearError = () => {
        setErrormsg("");
    };

    return (
        <div>
            {loader && <LinearProgress />}
            {/* // Data Grid will fill the size of the parent container */}
            <React.Fragment>
                <Grid container xs={12} style={{ padding: "25px 40px 0px 25px", alignItems: "center" }}>
                    <Grid item xs={6}>
                        <Typography className={classes.heading}>
                            Bulk Request for GST or DIN
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button className={classes.button} onClick={handleClickOpen}>
                            <PublishIcon style={{ width: "1.5rem", fontSize: "1.3rem", marginRight: "8px" }} />
                            <Typography component="span">Upload</Typography>
                        </Button>
                    </Grid>
                </Grid>
                <div className={classes.teamsTable}>
                    <div className="ag-theme-material">
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={colDefs}
                            defaultColDef={defaultColDef}
                            domLayout="autoHeight"
                            className={classes.table}
                            animateRows={true}
                            suppressDragLeaveHidesColumns={true}
                            cacheQuickFilter={true}
                            pagination={true}
                            paginationPageSize={9}
                            suppressCellFocus={true}
                        />
                    </div>
                </div>
            </React.Fragment >

            {/* Dialog for upload a file  */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogContent style={{ backgroundColor: "#fafafa" }} >
                    <Grid className={classes.dialogHeading}>
                        <div className={classes.dialogHeadingIcon}>
                            <img src={UploadIcon} />
                        </div>
                        <div className={classes.dialogSubHeading}>
                            <h1 className={classes.dialogSubHeadingh1}>Bulk Request for DIN or GST</h1>
                            <p className={classes.dialogSubHeadingp}>Upload a file with the required details to request DIN or GST info.</p>
                        </div>
                    </Grid>
                    <FormControl component="fieldset" style={{ width: "100%", }}>
                        <RadioGroup row aria-label="RequestType" className={classes.radioGroup} name="RequestType" value={value} onChange={handleChange} >
                            <div ref={requestDIN} className={classes.radioGroupRequestBox}>
                                <FormControlLabel value="DIN" className={classes.formControlLabel} control={<Radio color="primary" />} label="DIN Request" />
                            </div>
                            <div ref={requestGST} className={classes.radioGroupRequestBox}>
                                <FormControlLabel value="GST" className={classes.formControlLabel} control={<Radio color="primary" />} label="GST Request" />
                            </div>
                        </RadioGroup>
                    </FormControl>
                    <Grid container style={{ margin: "20px 0" }}>
                        <DropzoneArea
                            key={dropzoneKey}
                            acceptedFiles={[".csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                            onChange={handleFileChange}
                            maxFileSize={10 * 1010 * 1024}
                            filesLimit={1}
                            showPreviews={true}
                            showPreviewsInDropzone={false}
                            useChipsForPreview
                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                            previewChipProps={{ classes: { root: classes.previewChip } }}
                            Icon={() => null}
                            previewText={<div style={{ fontSize: "14px", paddingTop: "4px", color: "black" }}>The file <span style={{ color: "#221C53", fontWeight: 600 }}>header</span> must include <span style={{ color: "#221C53", fontWeight: 600 }}>"{value == "DIN" ? "DIN" : "GST"}"</span>. Only one file can be uploaded at a time.</div>}
                            dropzoneText={
                                <Box style={{ display: "grid", justifyContent: "center", justifyItems: "center", paddingTop: "12%" }}>
                                    <img src={CloudUpload} height={64} width={64} />
                                    <Typography style={{ fontSize: "16px", color: "#221C53", }}><span style={{ fontWeight: 900 }}>Select file from Device</span> <span style={{ color: "#00000099", fontWeight: 500 }}>or</span> <span style={{ fontWeight: 900 }}>Drag and drop</span></Typography>
                                    <Typography style={{ color: "#00000099", fontWeight: 500, fontSize: "14px" }}>Supported file format: <span style={{ color: "#221C53" }}>csv, .xls, .xlsx</span></Typography>
                                </Box>
                            }
                            dropzoneClass={classes.customDropzone}
                        />
                        {!files.length && <div style={{ fontSize: "14px", paddingTop: "6px", color: "black" }}>The file <span style={{ color: "#221C53", fontWeight: 600 }}>header</span> must include <span style={{ color: "#221C53", fontWeight: 600 }}>"{value == "DIN" ? "DIN" : "GST"}"</span>. Only one file can be uploaded at a time.</div>}
                    </Grid>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button disabled={!files.length || uploadLoader} style={{ marginBottom: "8px", width: "2rem" }} className={!files.length || uploadLoader ? classes.buttonDisabled : classes.button} onClick={uploadBulkFile}>
                            {uploadLoader ? <CircularProgress size={24} /> : "Upload"}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            <CustomSnackbar
                display={errormsg}
                message={errormsg}
                dismissNotification={clearError}
                severity={"error"}
            />
        </div>
    )
}

// export default BulkRequestDinGst
export default withStyles(styles, { withTheme: true })(BulkRequestDinGst);
